import React from 'react';
import BlogClassicData from '../data/blog/BlogList.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";

const BlogDetails = ({match: {params: {slug}}}) => {
    const data = BlogClassicData.find((post) => post.slug === slug);

    if (!data) {
        return (
            <Layout>
                <div>
                    <h1>404: Blog post not found</h1>
                </div>
            </Layout>
        );
    }
    const shareUrl = `${window.location.origin}/blog/${data.slug}`;
    const getImagePath = () => {
        if (!data.image) return `${window.location.origin}/social-logo.jpg`;
        if (data.image.startsWith('http')) {
            return data.image;
        }
        return `${window.location.origin}/${data.image}`;
    };
    const description = data.body && data.body.length > 0
        ? data.body[0].substring(0, 160) 
        : "Code Aims - offers a wide range of topics that are sure to help everyone grow and succeed.";
    return (
        <>
            <SEO title={`${data.title} - Blogs`} 
                description={description}
                image={getImagePath()}
                url={shareUrl}
            />
            <Layout>
                <div className="rn-blog-details-area">
                    <BlogDetailsContent 
                        data={data}
                        shareUrl={shareUrl}
                        imagePath={getImagePath()}
                    />
                </div>
            </Layout>
        </>
    )
}
export default BlogDetails;