import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BestLayout from '../common/BestLayout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MdArrowOutward } from "react-icons/md";
import ContactForm from '../elements/contact/ContactForm';
import SectionTitle from '../elements/sectionTitle/SectionTitle';

const SiteMap = () => {
    return (
        <>
            <SEO title="Site Map" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20 pb--15" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-750 bg_image" data-black-overlay="9" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/contact-banner.webp)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge"><span className="theme-gradient"> Explore and read all content </span></h3>
                                        </div>
                                        <h1 className="display-one">Code Aims <strong>Sitemap</strong></h1>
                                        <a className={`btn-default btn-icon round`} style={{fontWeight: 600}} href="#req-contact">
                                            Request a Proposal
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">

                        <div className="rn-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="title" >
                                            Services We Offer
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Web Development </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> WordPress Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> School Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Law Firm Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Wix Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Web Flow Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Figma Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Shopify Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Ecommerce Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Magento Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Web Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> SEO Services </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Social Media Marketing </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Paid Advertising </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> UX / UI </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Redesign Web  </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Website Migration </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Security Maintenance </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Digital Art </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> 2D Enviroment Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> 2D Character Design </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> NSFW Artwork Design </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="title mt--30">
                                            Explore Pages
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Design & Development Agency </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> About Us </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Blog </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Engage </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Our Work </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Services </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Terms and Condition </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Privacy Policy </Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <p className="sitemap-title">
                                            <Link to="#"><MdArrowOutward /> Terms of Service </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Contact Form  */}
                        <div className="rwt-contact-area rn-section-gapBottom" id='req-contact'>
                            <div className="container">
                                <div className="row mb--30">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Let's talk"
                                            title = "Have a <strong>project in mind?</strong>"
                                            description = ""
                                        />
                                    </div>
                                </div>
                                <div className="row mt--40 row--15" id='contact'>
                                    <div className="col-lg-5 mt_md--30 mt_sm--30 pb--30">
                                        <img src={`/images/bg/contact-image.webp`} alt={` Code Aims`} style={{width: '100%', maxHeight: '550px', height: '100%', objectFit: 'cover', borderRadius: '10px'}} />
                                    </div>
                                    <div className="col-lg-7">
                                        <ContactForm formStyle="contact-form-1" />
                                    </div>
                                </div>
                            </div>
                        </div>  
                        {/* Contact Form  */}
                    </div>
                </main>
            </BestLayout>
        </>
    )
}
export default SiteMap;