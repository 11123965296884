import React from 'react';

const BrandList = [
    {
        image: './images/brand/bocohstudios-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/abwtrade-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/grip-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/asghari-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/b-s-pro-cleaning-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/evening-standard-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/BSL-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/Ezi-metalrecycling-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/thesocial-nexes-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/hazarasociety-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/sea-water-logo.png',
        title: '',
        link: '#',
    },
    {
        image: './images/brand/shamilybusiness-logo.png',
        title: '',
        link: '#',
    },

]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={data.link}><img src={`${data.image}`} alt={data.title} /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
