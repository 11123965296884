import React, { useState, useEffect } from 'react';

const Cursor = () => {
    const [cursorPos, setCursorPos] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    const [isHovering, setIsHovering] = useState(false);
    let animationFrameId;

    const followCursor = (event) => {
        setCursorPos((prevPos) => {
        const speed = 0.1; 
        return {
            x: prevPos.x + (event.clientX - prevPos.x) * speed,
            y: prevPos.y + (event.clientY - prevPos.y) * speed,
        };
        });
        animationFrameId = requestAnimationFrame(() => followCursor(event));
    };

    useEffect(() => {
        const handleMouseMove = (event) => {
            cancelAnimationFrame(animationFrameId);
            followCursor(event);
        };

        const handleMouseEnter = () => setIsHovering(true);
        const handleMouseLeave = () => setIsHovering(false);

        window.addEventListener('mousemove', handleMouseMove);

        const links = document.querySelectorAll('a, button, .tab-button, .img-container, .rwt-card ');
        links.forEach((link) => {
            link.addEventListener('mouseenter', handleMouseEnter);
            link.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            cancelAnimationFrame(animationFrameId);
            links.forEach((link) => {
                link.removeEventListener('mouseenter', handleMouseEnter);
                link.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

  return (
    <div
        className={`custom-cursor ${isHovering ? 'hovering' : ''}`}
        style={{ left: `${cursorPos.x}px`, top: `${cursorPos.y}px` }}
        >
    </div>
    );
};

export default Cursor;