import React , {useState} from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>
    )
}

function ContactFormService({ formStyle }) {
    const [result, showResult] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const sendEmail = async (e) => {
        e.preventDefault();

        if (!recaptchaValue) {
            alert("Please complete the reCAPTCHA");
            return;
        }

        emailjs
        .sendForm(
            'service_fmu0o61',
            'template_ugdbc5h',
            e.target, 
            'SxlYvw80qTIhH-JyL'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showResult(true);
    };

    setTimeout(() => {
        showResult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <div className='form-field'>
                    <input 
                    type="text"
                    name="fullname"
                    id='fullname'
                    required
                    />
                    <label htmlFor='fullname'>Full Name <span>*</span></label>
                </div>
                <div className='form-field'>
                    <input 
                    type="email"
                    name="email"
                    id='emailaddress'
                    required
                    />
                    <label htmlFor='emailaddress'>Email Address <span>*</span></label>
                </div>
            </div>

             <div className="form-group">
                <div className='form-field'>
                    <input 
                    type="text"
                    name="phone"
                    id='phonenumber'
                    required
                    />
                    <label htmlFor='phonenumber'>Phone Number <span>*</span></label>
                </div>
            </div>

            <div className="form-group">
                <div className='form-field form-field-textarea'>
                    <textarea 
                    name="message"
                    id='message'
                    required
                    >
                    </textarea>
                    <label htmlFor='message'>Your Message <span>*</span></label>
                </div>
            </div>

            <ReCAPTCHA
                sitekey="6LcC3nMqAAAAAFYvXnruvOyZiHkpiEVUyLiW0EnP"
                onChange={(value) => setRecaptchaValue(value)}
            />;

            <div className="form-group mt--20">
                <button className="btn-default btn-large w-100" disabled={!recaptchaValue}>Submit</button>
            </div>  

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactFormService;