import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BestLayout from '../common/BestLayout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const TermsCondition = () => {
    return (
        <>
            <SEO title="Site Map" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20 pb--15" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-750 bg_image" data-black-overlay="9" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/contact-banner.webp)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge"><span className="theme-gradient"> Explore and read all content </span></h3>
                                        </div>
                                        <h1 className="display-one litle-page"> Terms & <strong>Condition</strong></h1>
                                        <p className="description "> 
                                            Please read the following Website <strong>Terms & Conditions</strong> (“T&C“) carefully as the provisions contained herein govern your access and use of the Website.
                                        </p>
                                        <Link className={`btn-default btn-icon round mt--20`} style={{fontWeight: 600}} to="/contact">
                                            Request a Proposal
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">

                        <div className="rn-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="title underline"> Terms and Conditions </h3>
                                        <h4 className='mt--40'> Welcome to CodeAims! </h4>
                                        <p className="desc-18"> 
                                            These Terms and Conditions ("Terms") govern your access to and use of our website and services. By engaging with CodeAims, you agree to these Terms. If you do not agree, please do not use our services.
                                        </p>
                                        <h4> 1. Services Provided </h4>
                                        <p className="desc-18"> 
                                            CodeAims specializes in custom web development, scratch-built solutions, and tailored digital products. We strive for excellence in delivering high-quality, professional results for our clients.
                                        </p>
                                        <h4> 2. User Responsibilities </h4>
                                        <ul className="desc-18">
                                            <li> <strong>Accurate Information:</strong> You agree to provide accurate, current, and complete information when working with us. </li>
                                            <li> <strong>Appropriate Use:</strong> You will not use our website or services for any illegal or unauthorized purposes. </li>
                                            <li> <strong>Ownership of Content:</strong> You ensure you own or have permission to use all materials provided to us for your project. </li>
                                        </ul>
                                        <h4> 3. Intellectual Property </h4>
                                        <p className="desc-18"> 
                                            All custom work created by CodeAims, including designs, code, and content, remains the intellectual property of CodeAims until full payment is made. Upon payment, ownership is transferred as agreed in the project contract.
                                        </p>
                                        <h4> 4. Payment Terms </h4>
                                        <ul className="desc-18">
                                            <li> Projects require a deposit before work begins, as outlined in the contract. </li>
                                            <li> Final payment is due upon project completion unless otherwise specified. </li>
                                            <li> Late payments may incur additional fees. </li>
                                        </ul>
                                        <h4> 5. Warranty and Support </h4>
                                        <p className="desc-18"> 
                                            CodeAims provides a 30-day warranty period for bug fixes and minor updates after project delivery. Extended support or additional features may incur extra charges.
                                        </p>
                                        <h4> 6. Limitation of Liability </h4>
                                        <p className="desc-18" style={{marginBottom: '15px'}}> 
                                            We strive for excellence, but CodeAims is not responsible for:
                                        </p>
                                        <ul className="desc-18">
                                            <li> Third-party software failures. </li>
                                            <li> Delays caused by factors beyond our control. </li>
                                            <li> Loss of data due to misuse or unauthorized modifications. </li>
                                        </ul>
                                        <h4> 7. Changes to Terms </h4>
                                        <p className="desc-18"> 
                                            CodeAims reserves the right to update these Terms at any time. Continued use of our services constitutes acceptance of any changes.
                                        </p>
                                        <h4> 8. Contact Us </h4>
                                        <p className="desc-18"> 
                                            If you have questions about these Terms, please contact us at <a href='mailto:support@codeaims.com'> <strong>Support@codeaims.com</strong> </a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </BestLayout>
        </>
    )
}
export default TermsCondition;