import React, { useState } from 'react';

const NewsletterOne = ({extraClass, newsletetrStyle}) => {

    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState("");

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const mailchimpUrl = "https://codeaims.us14.list-manage.com/subscribe/post?u=6972d373be25debafebebb821&amp;id=21c2300d75&amp;f_id=002b0ae3f0";

        
        const formData = new FormData();
        formData.append('EMAIL', email);

        fetch(mailchimpUrl, {
            method: 'POST',
            body: formData,
            mode: 'no-cors' 
        })
        .then(() => {
            setMessage('Thank you for subscribing!');
            setMessageType("success"); 
            setEmail('');
        })
        .catch(() => {
            setMessage('There was an issue with your subscription. Please try again.');
            setMessageType("error");
        });

        setTimeout(() => {
            setMessage("");
            setMessageType("");
        }, 5000);
    };

    return (
        <div className={`newsletter-area ${newsletetrStyle}`}>
            <div className="container">
                <div className={`row row--0 newsletter-wrapper align-items-center ${extraClass}`}>
                    <div className="col-lg-4">
                        <div className="newsletter-section-title">
                            <h3 className="title">Stay up to Date</h3>
                            <p className="description">Get the latest Code Aims news, updates, and trends delivered to you.</p>
                        </div>
                    </div>
                    <div className="col-lg-8" >
                        <form className="rn-newsletter mt_md--20 mt_sm--20" onSubmit={handleFormSubmit}>

                            <div className="form-group">
                                <input type="email" id='email' placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                {/* <div className='form-field'>
                                    <input 
                                        type="email"
                                        name="subscriber"
                                        id='emailAddress'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <label htmlFor='emailAddress'>Email Address <span>*</span></label>
                                </div> */}
                            </div>

                            <div className="form-group">
                                <button className="btn-default w-100" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Submit Now'}
                                </button>
                            </div>
                        </form>
                        {message && (
                            <p
                              className={`subscription-message mt--20 ${messageType}`}
                              style={{
                                  color: messageType === "success" ? "green" : "red",
                                  fontWeight: "bold",
                                  marginTop: "10px"
                              }}
                          >
                              {message}
                          </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewsletterOne;
