import React from 'react';
import { FiArrowRight } from "react-icons/fi";

const CalltoActionTwo = ({title, subtitle, btnText}) => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(/images/bg/thumbnail-bg.webp)`}} data-black-overlay="8">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--0" dangerouslySetInnerHTML={{__html: title}}></h2>
                                <p className='subtitle mt--20' dangerouslySetInnerHTML={{__html: subtitle}}></p>
                                <div className="call-to-btn text-center">
                                    <a className="btn-default btn-icon" href="/contact">{btnText} <i className="icon"><FiArrowRight /></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;