import React from 'react';

import { FiArrowRight, FiHeadphones , FiMail } from "react-icons/fi";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { GoCheckCircleFill } from "react-icons/go";
import { IoMdTime } from "react-icons/io";
import { FaUserFriends } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { MdOutlineNextWeek } from "react-icons/md";
import { SiMoneygram } from "react-icons/si";
import { DiRequirejs } from "react-icons/di";

const CoursesTab = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="course-tab">
                                <div className="inner">
                                    <div className='flex'>
                                        <div className="icon icon-color1">
                                            <FiHeadphones />
                                        </div>
                                        <h4 className="title  outline">
                                            Front End Development
                                        </h4>
                                    </div>
                                    <p className='desc-18'>
                                        Are you looking to boost your career in the ever-evolving world of technology? Web development is a high-demand skill that opens doors to countless opportunities. Code Aims, a trusted name in tech training, is offering an immersive Web Development Training Course in Quetta designed to help you build a solid foundation and advance your career in the field. 
                                    </p>
                                    <div className='flex mt--15'>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            HTML5
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            CSS3    
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Java Script   
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            WordPress 
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Web designing
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Live Website Design Project    
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Responsive Design 
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Production 
                                        </span>
                                    </div>
                                    <p className='mt--35 desc-18'><Link to="/registration">
                                        <strong>Course Detail:</strong>   
                                    </Link></p>
                                    <div className='flex gap--25 mt--10'>
                                        <span className='course-detail'> 
                                            <GiDuration />
                                            3 Months
                                        </span>
                                        <span className='course-detail'> 
                                            <FaUserFriends />
                                            5 Students
                                        </span>
                                        <span className='course-detail'> 
                                            <MdOutlineNextWeek />
                                            Monday-to-Friday
                                        </span>
                                        <span className='course-detail'> 
                                            <IoMdTime />
                                            5PM - 6PM
                                        </span>
                                        <span className='course-detail'> 
                                            <SiMoneygram />
                                            Fees: Rs 4000/= per month
                                        </span>
                                        <span className='course-detail'> 
                                            <DiRequirejs />
                                            Must have a Laptop
                                        </span>
                                    </div>
                                </div>
                                <div className="button-group mt--30 ">
                                    <Link className="btn-default btn-large round btn-icon" to="/registration"> Register Now <FiArrowRight /></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="course-tab" >
                                <div className="inner">
                                    <div className='flex'>
                                        <div className="icon icon-color2">
                                            <FiMail />
                                        </div>
                                        <h4 className="title outline">
                                            HTML5, CSS3, JavaScript
                                        </h4>
                                    </div>
                                    <p className='desc-18'>
                                        Are you looking to boost your career in the ever-evolving world of technology? Web development is a high-demand skill that opens doors to countless opportunities. Code Aims, a trusted name in tech training, is offering an immersive Web Development Training Course in Quetta designed to help you build a solid foundation and advance your career in the field.
                                    </p>
                                    <div className='flex mt--15'>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            HTML5
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            CSS3    
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Java Script   
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Bootstrap
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Flex / Grid
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Web designing
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Live Website Design Project    
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Hosting Websites   
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Responsive Design 
                                        </span>
                                    </div>
                                    <p className='mt--35 desc-18'><Link to="/registration">
                                        <strong>Course Detail:</strong>   
                                    </Link></p>
                                    <div className='flex gap--25 mt--10'>
                                        <span className='course-detail'> 
                                            <GiDuration />
                                            3 Months
                                        </span>
                                        <span className='course-detail'> 
                                            <FaUserFriends />
                                            5 Students
                                        </span>
                                        <span className='course-detail'> 
                                            <MdOutlineNextWeek />
                                            Monday-to-Friday
                                        </span>
                                        <span className='course-detail'> 
                                            <IoMdTime />
                                            5PM - 6PM
                                        </span>
                                        <span className='course-detail'> 
                                            <SiMoneygram />
                                            Fees: Rs 4000/= per month
                                        </span>
                                        <span className='course-detail'> 
                                            <DiRequirejs />
                                            Must have a Laptop
                                        </span>
                                    </div>
                                </div>
                                <div className="button-group mt--30 ">
                                    <Link className="btn-default btn-large round btn-icon" to="/registration"> Register Now <FiArrowRight /></Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="course-tab">
                                <div className="inner">
                                    <div className='flex'>
                                        <div className="icon icon-color2">
                                            <FiMail />
                                        </div>
                                        <h4 className="title outline">
                                            WordPress (CMS)
                                        </h4>
                                    </div>
                                    <p className='desc-18'>
                                        What You’ll Learn in Code Aims’ Web Development Training Course
                                    </p>
                                    <div className='flex mt--15'>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            WordPress
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Plugins
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Themes  
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Java Script   
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Hosting Websites   
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Responsive Design 
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Elementor
                                        </span>
                                        <span className='course-label'> 
                                            <GoCheckCircleFill />
                                            Security  
                                        </span>
                                    </div>
                                    <p className='mt--35 desc-18'><Link to="/registration">
                                        <strong>Course Detail:</strong>  
                                    </Link></p>
                                    <div className='flex gap--25 mt--10'>
                                        <span className='course-detail'> 
                                            <GiDuration />
                                            2 Months
                                        </span>
                                        <span className='course-detail'> 
                                            <FaUserFriends />
                                            5 Students
                                        </span>
                                        <span className='course-detail'> 
                                            <MdOutlineNextWeek />
                                            Monday-to-Friday
                                        </span>
                                        <span className='course-detail'> 
                                            <IoMdTime />
                                            5PM - 6PM
                                        </span>
                                        <span className='course-detail'> 
                                            <SiMoneygram />
                                            Fees: Rs 4000/= per month
                                        </span>
                                        <span className='course-detail'> 
                                            <DiRequirejs />
                                            Must have a Laptop
                                        </span>
                                    </div>
                                </div>
                                <div className="button-group mt--30 ">
                                    <Link className="btn-default btn-large round btn-icon" to="/registration"> Register Now <FiArrowRight /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CoursesTab;