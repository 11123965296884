import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import PageScrollTop from './components/pageToTop/PageScrollTop';

import './assets/scss/style.scss';

import HomePage from './pages/HomePage';
import AboutUs from "./pages/AboutUs";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import Services from './pages/Services';
import Blogs from './pages/Blogs';
import WebDevelopment from "./pages/WebDevelopment";
import Registration from './pages/Registration';
import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";  
import Author from "./pages/Author";
import Portfolio from './pages/Portfolio';
import PortfolioDetails from "./pages/PortfolioDetails";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";
import Contact from './pages/Contact';
import SiteMap from './pages/SiteMap';
import CookieConsent from './common/CookieConsent';
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Courses from './pages/Courses';

const TRACKING_ID = 'G-SH5D8BZ6E1';
const App = () => {
    return (
        <Router>
            <GAWrapper>
                <PageScrollTop>
                    <Switch>
                        <Route path={`/`} exact component={HomePage} />
                        <Route path={`/about-us`} exact component={AboutUs} />
                        <Route path={`/services`} exact component={Services} />
                        <Route path={`/web-development`} exact component={WebDevelopment} />
                        <Route path={`/contact`} exact component={Contact} />
                        <Route path={`/portfolio`} exact component={Portfolio} />
                        <Route path={`/portfolio/:slug`} exact component={PortfolioDetails} />
                        <Route path={`/blogs`} exact component={Blogs} />
                        <Route path={`/blogs/:slug`} exact component={BlogDetails} />
                        <Route path={`/category/:slug"}`} exact component={CategoryList} />
                        <Route path={`/archive/:slug`} exact component={Author} />
                        <Route path={`/tag/:slug`} exact component={TagList} />
                        <Route path={`/registration`} exact component={Registration} />
                        <Route path={`/farhad-developer`} exact component={PersonalPortfolio} />
                        <Route path={`/courses`} exact component={Courses} />
                        <Route path={`/terms-condition`} exact component={TermsCondition} />
                        <Route path={`/privacy-policy`} exact component={PrivacyPolicy} />
                        <Route path={`/sitemap`} exact component={SiteMap} />
                        <Route path="*" exact component={Error} />
                    </Switch>
                    <CookieConsent />
                </PageScrollTop>
            </GAWrapper>
        </Router>
    );
};

const GAWrapper = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
    }, []);
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);
    return <>{children}</>;
};

export default App;
