import React from 'react';
import SEO from "../common/SEO";
import ContactOne from '../elements/contact/ContactOne';
import HeaderOne from '../common/header/HeaderOne';
import BestLayout from '../common/BestLayout';

const Contact = () => {
    return (
        <>
            <SEO title="Contact Us" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20 pb--15" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-750 bg_image" data-black-overlay="9" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/contact-banner.webp)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge"><span className="theme-gradient">Have a Query</span></h3>
                                        </div>
                                        <h1 className="title strock display-one">Ready to transform <br />your business?</h1>
                                        <p className="description "> Are you looking for a <strong>web development team.</strong> We have years of experience  working with businesses to create custom websites that fit your brand. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">
                        {/* Start Contact Area  */}
                        <div className="rwt-contact-area rn-section-gap">
                            <div className="container">
                                <ContactOne />
                            </div>
                        </div>
                        {/* End Contact Area  */}
                    </div>
                </main>
            </BestLayout>
        </>
    )
}
export default Contact;