import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BestLayout from '../common/BestLayout';
import { FiArrowDown } from 'react-icons/fi';
import CoursesTab from '../elements/courses/CoursesTab';
import HeaderTopBar from '../common/header/HeaderTopBar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Courses = () => {
    return (
        <>
            <SEO title="Courses" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderTopBar HeaderSTyle="header-transparent"/>
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20 pb--15 dark-hyper" />

                    {/* Start Slider Area  */}
                    <div className="slider-area height-1020 bg-transparent slider-style-1 pb--100 pt--70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner text-center">
                                        <div className="react-image mb--20">
                                            <img src="./images/logo/social-logo.png" alt="Code Aims Logo" />
                                        </div>
                                        <h1 className="title display-two light-title">
                                            Transform Your Career <br />
                                            <span className="theme-gradient">Web Design</span> & {" "}
                                            <span className="theme-gradient">Development</span>
                                            <br />
                                            Courses
                                        </h1>
                                        <p className="description">
                                            Discover expert-led courses designed to help you master the latest technologies and advance your career in tech.
                                        </p>
                                        <div className="button-group mt--30 ">
                                            <Link className="btn-default btn-large round btn-icon" to="/registration"> Register Now <FiArrowDown /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">
                        {/* Start Contact Area  */}
                        <div className="rn-section-gap">
                            <div className="container">
                                <h2 className="title display-two light-title">
                                    Why<br />
                                    <span className="theme-gradient">Web Design</span> & {" "}
                                    <span className="theme-gradient">Development ?</span>
                                    <br />
                                    
                                </h2>
                                <p className="desc-18">
                                    In today’s fast-paced digital world, web developers are among the most sought-after professionals. With businesses shifting online and increasing their digital footprint, there’s no shortage of opportunities for skilled web developers. According to various industry reports, web development is expected to continue growing, making it a wise career choice.
                                </p>
                                <p className="desc-18">
                                    By enrolling in our <Link to="/registration"><strong>Web Development Training in Quetta</strong></Link>, you are not just learning coding skills – you are setting yourself up for a future in a field that offers high job security, attractive salaries, and endless growth opportunities.
                                </p>
                                <CoursesTab />
                            </div>
                        </div>
                        {/* End Contact Area  */}
                    </div>
                </main>
            </BestLayout>
        </>
    )
}
export default Courses;