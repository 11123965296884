import React from 'react'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
} from "react-share";

const SocialShareIcon = ({ shareUrl= "", data, round = false, center= "" }) => {
    if (!shareUrl) return null;
  return (
    <div className={`social-share-buttons ${center}`}>
        <FacebookShareButton url={shareUrl} title={data.title}>
            <FacebookIcon size={50} round={round} />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={data.title} >
            <TwitterIcon size={50} round={round} />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={data.title} summary={data.description} >
            <LinkedinIcon size={50} round={round} />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} title={data.title} separator=":: ">
            <WhatsappIcon size={50} round={round} />
        </WhatsappShareButton>
    </div>
  )
}

export default SocialShareIcon