import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BestLayout from '../common/BestLayout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Site Map" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20 pb--15" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-750 bg_image" data-black-overlay="9" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/contact-banner.webp)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge"><span className="theme-gradient"> Explore and read all content </span></h3>
                                        </div>
                                        <h1 className="display-one litle-page"> Privacy <strong>Policy</strong></h1>
                                        <p className="description "> 
                                            The provisions set out in this <strong>Privacy Policy</strong> prescribe our policies regarding the use, processing and dissemination of information submitted by you through or in connection with the Website, and applies to all Users of the Website.
                                        </p>
                                        <Link className={`btn-default btn-icon round mt--20`} style={{fontWeight: 600}} to="/contact">
                                            Request a Proposal
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">

                        <div className="rn-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="title underline"> Privacy Policy </h3>
                                        <p className="desc-18"> 
                                            <strong>Effective Date:</strong> 1 January 2024 <br />
                                            CodeAims is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information.
                                        </p>
                                        <h4> 1. Information We Collect </h4>
                                        <ul className='desc-18'>
                                            <li> <strong>Personal Information:</strong> Name, email address, phone number, and any other details you provide. </li>
                                            <li> <strong>Usage Data:</strong> Information about how you interact with our website, including IP address and browsing behavior. </li>
                                            <li> <strong>Project Data: </strong> Files, documents, and other materials you share with us for project purposes. </li>
                                        </ul>
                                        <h4> 2. How We Use Your Information </h4>
                                        <ul className='desc-18'>
                                            <li> To communicate with you about your project. </li>
                                            <li> To improve our website and services. </li>
                                            <li> To comply with legal obligations. </li>
                                        </ul>
                                        <h4> 3. Sharing Your Information </h4>
                                        <p className="desc-18" style={{marginBottom: '15px'}}> 
                                            We do not sell or rent your information to third parties. However, we may share information:
                                        </p>
                                        <ul className='desc-18'>
                                            <li> With trusted partners (e.g., hosting providers) to facilitate our services. </li>
                                            <li> If required by law or to protect our legal rights. </li>
                                        </ul>
                                        <h4> 4. Data Security </h4>
                                        <p className="desc-18"> 
                                            We implement strict security measures to protect your data from unauthorized access, alteration, or disclosure. However, no system can guarantee 100% security.
                                        </p>
                                        <h4> 5. Cookies </h4>
                                        <p className="desc-18"> 
                                            Our website may use cookies to enhance your experience. You can control cookie settings through your browser.
                                        </p>
                                        <h4> 6. Your Rights </h4>
                                        <ul className='desc-18'>
                                            <li> Access and update your personal data. </li>
                                            <li> Request deletion of your data. </li>
                                            <li> Opt-out of marketing communications. </li>
                                        </ul>
                                        <p className="desc-18"> 
                                            To exercise these rights, contact us at <a href='mailto:support@codeaims.com'> <strong>Support@codeaims.com</strong> </a>.
                                        </p>
                                        <h4> 7. Changes to This Policy </h4>
                                        <p className="desc-18"> 
                                            We may update this Privacy Policy as needed. The updated policy will be posted on our website, and continued use constitutes acceptance of the changes.
                                        </p>
                                        <h4> 8. Contact Us </h4>
                                        <p className="desc-18"> 
                                            If you have questions about our Privacy Policy, please contact us at <a href='mailto:support@codeaims.com'> <strong>Support@codeaims.com</strong> </a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </BestLayout>
        </>
    )
}
export default PrivacyPolicy;