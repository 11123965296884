import React, { Component } from 'react';

class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 30.187488,
      lng: 66.995520
    },
    zoom: 11
  };

  render() {
    return (
      <div className="google-map-style-1">
        <iframe className='google-map' title='Code Aims Address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1219.4025591702734!2d67.03188227757634!3d30.180243229693527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2df3c91b0d259%3A0xec4f97c87f9c9c28!2sCodeaims!5e0!3m2!1sen!2s!4v1703943961230!5m2!1sen!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;