import React from 'react'
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceOne from "../elements/service/ServiceOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import AccordionOne from '../elements/accordion/AccordionOne';
import SlpitOne from '../elements/split/SlpitOne';
import SlipFive from '../elements/split/SlipFive';
import InfiniteScrollAnimation from '../elements/scrollAnimation/InfiniteScrollAnimation';
import CalltoActionSix from '../elements/calltoaction/CalltoActionSix';
import Separator from '../elements/separator/Separator';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

const TAGS = ['Professional Web Development Agency', 'E-Commerce Websites', 'Shopify', 'WordPress', 'Redesign Web', 'Affordable Digital Solutions Company', 'Web Development', 'Web Design', 'Responsive Design', 'Custom Web Development Services', 'User Experience', 'UX/UI', 'HTML5', 'CSS3', 'Graphic Design', 'Tech Solutions', 'Digital Solutions', 'Digital Art Services', 'Developer Community', 'Small Business Website Development', 'top-notch custom web development', 'Animation', 'Tailwind', 'BootStrap', 'Restaurant Site', 'SEO', '2d Arts', 'Concept art', "2d Character design", "2d game assets", "2d Enviroment", "Youtube Thumbnail Design", "NSFW artworks", "fury arts", 'Logo and Branding Digital Art'];

const AboutUs = () => {

    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderTopBar HeaderSTyle="header-transparent"/>
                <HeaderOne btnStyle="round btn-icon" HeaderSTyle="header-transparent pt--20" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/main-about-banner.webp)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">
                                            your development partner
                                        </span></h3>
                                    </div>
                                    <h1 className="title display-one strock">
                                        We Design & Develop <br></br> Web
                                        Solutions
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title reveal-text">
                                        Empowering businesses with exceptional web design and support.
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="desc-18 mb--10">  
                                    At <strong>Code Aims</strong>, we deliver <strong>expert UX / UI design, branding, digital marketing, digital art, enviroment & character design and development solutions</strong>. Our services include logo design, brand building, SEO, social media marketing, and web application development. With hands-on experience, we create impactful designs and strategies that drive results. We also ensure your digital assets stay secure and updated with reliable maintenance services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <InfiniteScrollAnimation TAGS={TAGS}/>
                            </div>
                        </div>
                    </div>
                </div>

                <SlpitOne />
                <SlipFive />
                <Separator />

                {/* Start Timline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle="We Have Solutions"
                                    title = "Our <strong>Working Process.</strong>"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-xl-10 col-lg-12 offset-xl-1 mt--50">
                                <TimelineTwo classVar="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timline Area  */}     

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we do"
                                    title = "Our <strong>Expertise</strong>"
                                    description = ""/>
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle="service__style--1 radius mt--25 rbt-border"
                            textAlign="text-start"/>
                    </div>
                </div>
                {/* End Service Area  */}  

                <Separator/> 
                
                {/* Start Accordion Area  */}
                <div className="rn-accordion-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "FAQ"
                                    title = "Frequently Asked <strong>Questions.</strong>"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row mt--35 row--20">
                            <AccordionOne customStyle="rn-accordion-01" />
                        </div>
                    </div>
                </div>
                {/* End Accordion Area  */}

                {/* call to action  */}
                <CalltoActionSix />

                {/* footer  */}
                <FooterTwo />
                <Copyright />   
            </main>
        </>
    )
}

export default AboutUs;
